@import "helpers"

.op-border-box-grid
  display: grid

  &--row-action
    align-items: center
    justify-content: flex-end

@media screen and (min-width: $breakpoint-md)
  .op-border-box-grid
    // Distribute columns evenly on desktop
    grid-auto-columns: minmax(0, 1fr)
    grid-auto-flow: column
    justify-content: flex-start
    align-items: center

    &--heading,
    &--row-item
      &:not(:first-child)
        padding-left: 6px

      &:not(:last-child)
        padding-right: 6px

    &--mobile-heading,
    &--row-label
      display: none

    &--main-column
      grid-column: span 2

@media screen and (max-width: $breakpoint-md)
  .op-border-box-grid
    grid-template-columns: 1fr auto
    grid-auto-flow: row

    &--heading,
    &--no-mobile
      display: none

    &--row-item
      grid-column: 1

    &--row-action
      grid-column: 2
      grid-row: 1
