//-- copyright
// OpenProject is an open source project management software.
// Copyright (C) the OpenProject GmbH
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// OpenProject is a fork of ChiliProject, which is a fork of Redmine. The copyright follows:
// Copyright (C) 2006-2013 Jean-Philippe Lang
// Copyright (C) 2010-2013 the ChiliProject Team
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License
// as published by the Free Software Foundation; either version 2
// of the License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See COPYRIGHT and LICENSE files for more details.
//++

@media screen and (max-width: $breakpoint-sm)
  body, html
    position: relative
    -webkit-overflow-scrolling: touch
    height: 100%

  #main
    margin-top: var(--header-height)

  ._header-scrolled
    #main
      margin-top: 0
    #main,
    #content-wrapper
      height: 100dvh

  #content-body,
  #content-header
    padding-left: 15px
    padding-right: 15px

  #breadcrumb,
  .hidden-for-mobile
    display: none !important

  h2
    font-size: 1.4rem

@media screen and (min-width: $breakpoint-sm)
  .hidden-for-desktop
    display: none !important

@media screen and (max-width: $breakpoint-md)
  .hidden-for-tablet
    display: none !important

@media screen and (max-width: $breakpoint-lg)
  .hidden-for-small-laptops
    display: none !important

  #main
    grid-template-columns: auto

  #content:has(#content-bodyRight > *)
    grid-template-columns: 1fr auto

  // As soon as the split screen has content (so basically when its opened), it takes the available whole space
  #content-bodyRight:has(*)
    grid-column: 1 / 3
    grid-row: 1 / 3
    background-color: var(--body-background)
    z-index: 1

@media screen and (max-width: $breakpoint-lg) and (min-width: $breakpoint-sm)
  .hidden-for-tablet-and-small-laptops
    display: none !important
