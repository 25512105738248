.work-packages-activities-tab-journals-item-component
    &--action-menu
        button
            padding-top: 3px // for whatever reason, the dots in the action menu are not perfectly aligned in center vertically by default
    &--user-name-container
        max-width: 80%
    &--user-name
        @media screen and (min-width: $breakpoint-sm)
            max-width: 40%
    &--notification-dot-icon
        color: var(--bgColor-accent-emphasis)
    &--header-start-container
        flex-grow: 1


